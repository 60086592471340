import { httpAPI } from "@/api/httpAPI";
import i18n from '@/i18n';

const DocTypes = {
    IncomingDocument: 3,
    OutgoingDocument: 4,
    InnerDocument: 5,
    NPADocument: 17,
    ProtocolDocument: 18
};
function getOtherNumber(state) {
    if (state.numberParam === '2')
        return state.otherNumber;
    
    if (state.numberParam === '3')
        return state.customNumber;

    return null;
}

const registerDocument = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,

        resolve: null,
        reject: null,

        counters: [],
        reserve: [],
        documentType: null,
        documentId: null,

        directive: false,
        nomenclature: null,

        includePreviousYear: false,
        numberParam: "0",
        otherNumber: "",
        customNumber: "",
        customRegDate: null,
        reserveNumber: null,
        reserveDate: null,
        counter: null,

        potentialRegNumber: "",
        potentialRegNumberLoading: false,
        isPotentialRegNumberError: false,

        minCustomRegDate: null,
        maxCustomRegDate: null,

        needSendToInspect: false,
        canSendToInspect: null,

        isProtocol: false
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_DOCUMENT_TYPE(state, payload) {
            state.documentType = payload;
        },
        SET_DOCUMENT_ID(state, payload) {
            state.documentId = payload;
        },
        SET_INCLUDE_PREVIOUS_YEAR(state, payload) {
            state.includePreviousYear = payload;
        },
        SET_NUMBER_PARAM(state, payload) {
            state.otherNumber = "",
            state.customNumber = "",
            state.customRegDate = state.maxCustomRegDate,
            state.reserveNumber = null,
            state.numberParam = payload;
        },
        SET_OTHER_NUMBER(state, payload) {
            state.otherNumber = payload;
        },
        SET_CUSTOM_NUMBER(state, payload) {
            state.customNumber = payload;
        },
        SET_CUSTOM_REGDATE(state, payload) {
            state.customRegDate = payload;
        },
        SET_RESERVE_NUMBER(state, payload) {
            state.reserveNumber = payload;
        },
        SET_RESERVE_DATE(state, payload) {
            state.reserveDate = payload;
        },
        SET_COUNTER(state, payload) {
            state.counter = payload;
        },
        SET_POTENTIAL_REG_NUMBER(state, payload) {
            state.potentialRegNumber = payload;
        },
        SET_POTENTIAL_REG_NUMBER_LOADING(state, payload) {
            state.potentialRegNumberLoading = payload;
        },
        SET_IS_POTENTIAL_REG_NUMBER_ERROR(state, payload) {
            state.isPotentialRegNumberError = payload;
        },
        SET_RESERVE(state, payload) {
            state.reserve = payload;
        },
        SET_COUNTERS(state, payload) {
            state.counters = payload;
        },
        SET_DIRECTIVE(state, payload) {
            state.directive = payload;
        },
        SET_NOMENCLATURE(state, payload) {
            state.nomenclature = payload;
        },
        SET_MIN_CUSTOM_REGDATE(state, payload) {
            state.minCustomRegDate = payload;
        },
        SET_MAX_CUSTOM_REGDATE(state, payload) {
            state.maxCustomRegDate = payload;
        },
        SET_NEED_SEND_TO_INSPECT(state, payload) {
            state.needSendToInspect = payload;
        },
        SET_CAN_SEND_TO_INSPECT(state, payload) {
            state.canSendToInspect = payload;
        }
    },
    actions: {
        async open({ commit, dispatch, getters, rootGetters }, { docId, docType }) {
            //#region Сбрасываем параметры
            commit('SET_CAN_SEND_TO_INSPECT', null);
            commit('SET_DOCUMENT_TYPE', docType);
            commit('SET_DOCUMENT_ID', docId);
            commit('SET_POTENTIAL_REG_NUMBER', "");
            commit('SET_INCLUDE_PREVIOUS_YEAR', false);
            commit('SET_NUMBER_PARAM', "0");

            let currentDate = this._vm.$moment(await dispatch('references/getTimestamp', null, { root: true })).format('YYYY-MM-DD');
            commit('SET_CUSTOM_REGDATE', currentDate);
            commit('SET_MAX_CUSTOM_REGDATE', currentDate);
            commit('SET_MIN_CUSTOM_REGDATE', this._vm.$moment(rootGetters['actionsource/getDataSourceEntity']?.CreateDate).format('YYYY-MM-DD') );
            //#endregion

            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);

            //загружаем список доступных журналов регистрации
            let countersExists = await dispatch('fetchCounters');

            //если нет журналов
            if (!countersExists) {
                commit('SET_VISIBLE', false);
                commit('SET_LOADING', false);
                throw { showNotify: true, message: "Нет_доступных_журналов_регистрации_для_типа" };
            }

            if (getters.isIncoming) {
                commit('SET_DIRECTIVE', false);
            }

            //getDataSourceEntity.Nomenclature
            if (getters.isIncoming || getters.isOutgoingWithoutNomenclature) {                
                commit('SET_NOMENCLATURE', null);
                let nomenclaturesExists = await dispatch('fetchNomenclatures');

                if (!nomenclaturesExists) {
                    commit('SET_VISIBLE', false);
                    commit('SET_LOADING', false);
                    throw { showNotify: true, message: "Нет_доступных_номенклатур" };
                }
            }
            
            await dispatch('fetchCounterReserve');
            await dispatch('fetchPotentialRegNumber');

            commit('SET_LOADING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state, getters }) {
            commit('SET_VISIBLE', false);
            state.resolve({ 
                id: state.documentId,
                param: {
                    SelectedCounterId: state.counter.id,
                    IsDIRORD: getters.isIncoming ? state.directive : false,
                    NomenclatureId: getters.isIncoming || getters.isOutgoingWithoutNomenclature ? (state.nomenclature?.id ?? '00000000-0000-0000-0000-000000000000') : '00000000-0000-0000-0000-000000000000',
                    FromReserv: state.numberParam === '1',
                    ReserveItem: state.numberParam === '1' ? { Value: state.reserveNumber } : null,
                    FromOther: state.numberParam === '2',
                    FromCustom: state.numberParam === '3',
                    OtherNumber: getOtherNumber(state),
                    NotifySign: null,
                    RegDate: state.numberParam === '1' ?
                        `/Date(${Date.parse(this._vm.$moment.utc(state.reserveDate, 'DD.MM.YYYY').toDate())})/` :
                        getters.isNPA || getters.isProtocol || (state.numberParam === '3' && getters.isIncoming) ? `/Date(${Date.parse(state.customRegDate)})/` : null,
                    NeedSendToInspect: state.needSendToInspect === true
                },
                potentialNumber: state.potentialRegNumber
            });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        async fetchPotentialRegNumber({ state, getters, commit, rootGetters }) {

            if (!state.counter) {
                commit('SET_POTENTIAL_REG_NUMBER', "");
                commit('SET_IS_POTENTIAL_REG_NUMBER_ERROR', false);
                return;
            }

            commit('SET_POTENTIAL_REG_NUMBER_LOADING', true);

            let response = await httpAPI({
                url: `/api/references/documentregistrationnumber`,
                method: 'POST',
                headers: { 'isCommon': rootGetters['actionsource/isDataSourceCommon'] },
                data: {
                    Content: JSON.stringify({ 
                        id: state.documentId,
                        param: {
                            SelectedCounterId: state.counter.id,
                            IsDIRORD: getters.isIncoming ? state.directive : false,
                            NomenclatureId: getters.isIncoming || getters.isOutgoingWithoutNomenclature ? (state.nomenclature?.id ?? '00000000-0000-0000-0000-000000000000') : '00000000-0000-0000-0000-000000000000',
                            FromReserv: state.numberParam === '1',
                            ReserveItem: state.numberParam === '1' ? { Value: state.reserveNumber } : null,
                            FromOther: state.numberParam === '2',
                            FromCustom: state.numberParam === '3',
                            OtherNumber: getOtherNumber(state),
                            NotifySign: null,
                            RegDate: state.numberParam === '3' && getters.isIncoming ? `/Date(${Date.parse(state.customRegDate)})/` : null,
                        }
                    })
                },
                skipErrorHandler: true
            });

            commit('SET_POTENTIAL_REG_NUMBER_LOADING', false);
            commit('SET_POTENTIAL_REG_NUMBER', response?.data?.payload?.Data?.Object?.PreparedNumber ?? "");

            if (state.canSendToInspect == null) {
                commit('SET_CAN_SEND_TO_INSPECT', response?.data?.payload?.Data?.Object?.NeedSendToInspect ?? false);
                commit('SET_NEED_SEND_TO_INSPECT', response?.data?.payload?.Data?.Object?.NeedSendToInspect ?? false);                
            }

            commit('SET_IS_POTENTIAL_REG_NUMBER_ERROR', response?.data?.payload?.Result === "ERROR");
        },
        async fetchCounters({ state, commit }) {

            //Делаем запрос к API
            let response = await httpAPI({
                url: `/api/references/counters/${DocTypes[state.documentType]}`,
                method: 'GET'
            });

            if (response) {
                //если мы не получили журналы
                if (!response.data.payload?.data?.length)
                    return false;

                //парсим объекты
                let counters = this._vm.$_(response.data.payload.data
                    .map( x => ({ id: x[0], Value: `${x[2]} (${x[1]})`, number: x[3], isPreviousYear: x[5] !== '0' })))
                    .orderBy(['isPreviousYear', 'Value'], ['asc', 'asc'])
                    .value();
                
                //добавляем отсортированные объекты в пул доступных
                commit('SET_COUNTERS', counters);
                //признак, что первый доступный журнал является журналом текущего года
                let isCurrentYearCounter = state.counters[0].isPreviousYear === false;
                //устнаваливаем либо журнал, либо ничего не выбрано
                commit('SET_COUNTER', isCurrentYearCounter ? state.counters[0] : null );

                return true;
            }

            return false;
        },
        async fetchCounterReserve({ state, commit }) {

            //если на момент вызова нет выбранного счетчика сбрасываем параметры резерва
            if (!state.counter) {
                commit('SET_RESERVE', []);
                commit('SET_RESERVE_NUMBER', null);
                commit('SET_RESERVE_DATE', null);
                return;
            }

            let response = await httpAPI({
                url: `/api/references/counterreserve/${state.counter.id}`,
                method: 'GET'
            });            
            let responseData = response?.data.payload.Data?.Object?.Data;

            //парсим вариант резервных номеров по счетчику
            let reserve = responseData?.map(x => ({ id: x[0], Value: `№ ${x[0]} ${i18n.t('от_время')} ${x[1]}`, date: x[1] })) ?? [];
            commit('SET_RESERVE', reserve);
            //устанавливаем выбранным первый элемент
            commit('SET_RESERVE_NUMBER', state.reserve?.length ? state.reserve[0].id : null);
            commit('SET_RESERVE_DATE', state.reserve?.length ? state.reserve[0].date : null);
        },
        async fetchNomenclatures({ dispatch, commit }) {
            let nomenclatures = await dispatch('references/getNomenclatures', { forDepartment: false }, { root: true });

            if (!nomenclatures?.length)
                return false;

            commit('SET_NOMENCLATURE', { id: nomenclatures[0][0], Text: `${nomenclatures[0][1]} ${nomenclatures[0][2]}` });
            return true;
        },
        async onCounterChange({ commit, dispatch }, counter) {
            commit('SET_COUNTER', counter );
            commit('SET_NUMBER_PARAM', "0" );
            await dispatch('fetchCounterReserve');
            await dispatch('fetchPotentialRegNumber');
        },
        async onIncludePreviousYearChange({ state, commit, dispatch }, value) {
            commit('SET_INCLUDE_PREVIOUS_YEAR', value );

            if (!value)
            {
                let currCounters = state.counters.filter(counter => !counter.isPreviousYear)
                await dispatch('onCounterChange', currCounters.length ? currCounters[0] : null);
            }
        },
        async onNumberParamChange({ commit, dispatch }, value) {
            commit('SET_NUMBER_PARAM', value );

            if (value === "0")
                await dispatch('fetchPotentialRegNumber');
            else
                commit('SET_POTENTIAL_REG_NUMBER', "");
        },
        async onReserveNumberChange({ commit, dispatch, state }, value) {
            commit('SET_RESERVE_NUMBER', value);
            let date = state.reserve?.find(x => x.id == value)?.date;
            commit('SET_RESERVE_DATE', date);
            await dispatch('fetchPotentialRegNumber');
        },
        async onOtherNumberChange({ commit, dispatch }, value) {
            let number = parseInt(value);

            if (!isNaN(number) && number > 0) {
                commit('SET_OTHER_NUMBER', value );
                await dispatch('fetchPotentialRegNumber');
            }
            else
                commit('SET_POTENTIAL_REG_NUMBER', "" );
        },
        async onCustomNumberChange({ commit }, value) {
            commit('SET_CUSTOM_NUMBER', value );
            commit('SET_POTENTIAL_REG_NUMBER', value );
        },
        async onCustomRegDateChange({ commit }, value) {
            commit('SET_CUSTOM_REGDATE', value );
        },
        async onNomenclatureChange({ commit, dispatch }, value) {
            commit('SET_NOMENCLATURE', value);
            await dispatch('fetchPotentialRegNumber');
        }
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        isIncoming: (s) => s.documentType === 'IncomingDocument',
        isProtocol: (s) => s.documentType === 'ProtocolDocument',
        isNPA: (s) => s.documentType === 'NPADocument',
        isOutgoingWithoutNomenclature: (s, g, rS, rG) => s.documentType === 'OutgoingDocument' && rG['actionsource/getDataSourceEntity']?.Nomenclature === null,
        isDirective: (s) => s.directive,
        getNomenclature: (s) => s.nomenclature,
        hasPreviousYearCounters: (s) => s.counters.filter(counter => counter.isPreviousYear).length > 0,
        isIncludePreviousYear: (s) => s.includePreviousYear,
        getNumberParam: (s) => s.numberParam,
        getOtherNumber: (s) => s.otherNumber,
        getCustomNumber: (s) => s.customNumber,
        getCustomRegDate: (s) => s.customRegDate,
        getReserveNumber: (s) => s.reserveNumber,
        getCounter: (s) => s.counter,
        getPotentialRegNumber: (s) => s.potentialRegNumber,
        isPotentialRegNumberLoading: (s) => s.potentialRegNumberLoading,
        isPotentialRegNumberError: (s) => s.isPotentialRegNumberError,
        getReserve: (s) => s.reserve,
        getCounters: (s) => s.includePreviousYear ? s.counters : s.counters.filter(counter => !counter.isPreviousYear),
        getMinCustomRegDate: (s) => s.minCustomRegDate,
        getMaxCustomRegDate: (s) => s.maxCustomRegDate,
        isNeedSendToInspect: (s) => s.needSendToInspect,
        isCanSendToInspect: (s) => s.canSendToInspect,
    }
}

export default registerDocument;